var TextAreaCounter = function () {
    var initialize = function() {
        $("textarea[maxlength][id]").each(function() {
            var textArea = $(this);
            textArea.stopVerbosity({
                indicatorPhrase: ['[countdown]', 'Zeichen verbleiben'],
                indicatorPosition: 'after',
                indicatorElementType: 'small',
                limit: parseInt(textArea.attr('maxlength')),
                indicatorId: textArea.attr('id') + "_counter"
            });
            $('#' + textArea.attr('id') + "_counter").addClass('help-block');
        });
    };
    initialize();
}