var ProgressIndikator = function () {
    var visible = false;

    this.Show = function () {
        if (visible)
            return;
        visible = true;
        $('body').append('<div id="divProgressIndicator" class="divMessageBox"><div class="loaderImage"></div></div>');
        $("#divProgressIndicator").fadeIn();
    };

    this.Hide = function () {
        if (!visible)
            return;
        $("#divProgressIndicator").fadeOut(function () {
            $(this).remove();
            visible = false;
        });
    };
};